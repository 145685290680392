<template>
  <div>
    <!-- <h1 v-show="showTabBar" class="text-h1">稽核領域管理</h1>
    <v-tabs v-show="showTabBar" backgroundColor="transparent">
      <v-tab class="pa-0 mr-4" to="/audit-field/risk-type"
        >風險類型與項目</v-tab
      >
      <v-tab class="pa-0" to="/audit-field/unit-field">單位稽核領域</v-tab>
    </v-tabs> -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // tab: "",
    };
  },
  computed: {
    showTabBar() {
      if (this.$route.params?.id) return false;
      else return true;
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss"></style>
